<template>
  <div>
    <Breadcrumbs title="Embarcações" />

    <!-- Container-fluid starts-->
    <div class="container-fluid">
      <div class="row">
        <div class="col-sm-12">
          <px-card>
            <div slot="with-padding">
              <div class="form theme-form">
                <div class="row">
                  <div class="col-sm-2 pt-3">
                    <div class="form-group mb-0">
                      <label class="d-inline-flex align-items-center w-100">
                        Filtrar:
                        <b-form-input id="filter-input" :model="filter" :value="filter" type="search"
                          placeholder="Digite..." class="form-control form-control-sm ml-2"
                          @keyup="debounceInput" @click="debounceInput">
                        </b-form-input>
                      </label>
                    </div>
                  </div>
                  <div class="col-sm-3 pt-3">
                    <div class="form-group mb-0">
                      <label class="d-inline-flex align-items-center w-100">
                        Valor: <!-- <vue-slider v-model="fvalues" :min="min" :max="max" :enable-cross="false"
                        :tooltip="'always'" :tooltipPlacement="['left', 'right']"
                        :tooltip-formatter="(val) => 'R$ ' + (val ? val : 0)" :useKeyboard="false"
                        @drag-end="valorFilterChanged">
                        </vue-slider> -->
                        <b-form-input id="filter-valor-min" :model="fValorMin" :value="fValorMin" type="number"
                          placeholder="Mín" class="form-control form-control-sm ml-2"
                          @input="(val) => { extraFilterChanged(val, 'fValorMin') }">
                        </b-form-input>
                        <b-form-input id="filter-valor-max" :model="fValorMax" :value="fValorMax" type="number"
                          placeholder="Máx" class="form-control form-control-sm ml-2"
                          @input="(val) => { extraFilterChanged(val, 'fValorMax') }">
                        </b-form-input>
                      </label>
                    </div>
                  </div>
                  <div class="col-sm-3 pt-3">
                    <div class="form-group mb-0">
                      <label class="d-inline-flex align-items-center w-100">
                        Tamanho: <!-- <vue-slider v-model="fvaluesTamanho" :min="minTamanho" :max="maxTamanho"
                        :enable-cross="false" :tooltip="'always'" :tooltipPlacement="['left', 'right']"
                        :tooltip-formatter="(val) => '' + val" :useKeyboard="false" @drag-end="tamanhoFilterChanged">
                      </vue-slider> -->
                        <b-form-input id="filter-tamanho-min" :model="fTamanhoMin" :value="fTamanhoMin" type="number"
                          placeholder="Mín" class="form-control form-control-sm ml-2"
                          @input="(val) => { extraFilterChanged(val, 'fTamanhoMin') }">
                        </b-form-input>
                        <b-form-input id="filter-tamanho-max" :model="fTamanhoMax" :value="fTamanhoMax" type="number"
                          placeholder="Máx" class="form-control form-control-sm ml-2"
                          @input="(val) => { extraFilterChanged(val, 'fTamanhoMax') }">
                        </b-form-input>
                      </label>
                    </div>
                  </div>
                  <div class="col-sm-4 text-right  pt-3">
                    <div class="form-group mb-0">
                      <label class="d-inline-flex align-items-center">
                        Status:&nbsp;
                        <b-form-select v-model="fStatus" size="sm" :options="statusOptions"
                          @change="statusFilterChanged">
                        </b-form-select>
                      </label>&nbsp;
                      <label class="d-inline-flex align-items-center">
                        Exibir&nbsp;
                        <b-form-select v-model="perPage" size="sm" :options="pageOptions" @change="savePerPage">
                        </b-form-select>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </px-card>
        </div>
      </div>
    </div>
    <!-- Container-fluid Ends-->

    <!-- Container-fluid starts-->
    <div class="container-fluid">
      <div class="row">
        <div class="col-sm-12">
          <px-card>
            <div slot="with-padding">
              <div class="form theme-form">
                <div class="row">
                  <div class="col-sm-12">
                    <div class="form-group mb-0">

                      <div class="table-responsive mb-0">
                        <b-table class="table-striped mb-0" style="font-size: 12px" :items="dataList" :fields="fields"
                          responsive="sm" :per-page="perPage" :current-page="currentPage" :sort-by.sync="sortBy"
                          :sort-desc.sync="sortDesc" :sort-compare="sortCompare" :filter="filter"
                          :filter-function="filterTable" :filter-included-fields="filterOn" @filtered="onFiltered"
                          @sort-changed="onSort">


                          <template v-slot:cell(media)="{ item }">
                            <img
                              style="width: 50px;  height: 50px;  aspect-ratio : 1 / 1;  object-fit: cover;  border-radius: 10px;"
                              v-if="item.post_meta && item.post_meta['medias'] && item.post_meta['medias'].length > 0"
                              :src="`https://crm.internauticaguaruja.com.br${(item.post_meta['medias'].split(';'))[0]}`"
                              alt="" />
                          </template>


                          <template v-slot:cell(post_title)="{ item }">
                            <router-link tag="a" v-if="item.post_title" class="font-weight-bold action-edit primary"
                              :to="{ path: '/vessels/' + item.id }">
                              {{ item.post_title }}
                            </router-link>
                          </template>

                          <template v-slot:cell(id)="{ item }">
                            <span v-if="item.id">#{{ item.id }}</span>
                          </template>

                          <template v-slot:cell(modelo)="{ item }">
                            <span v-if="item.post_meta['modelo']">{{ item.post_meta['modelo'] }}</span>
                          </template>

                          <template v-slot:cell(marca)="{ item }">
                            <span v-if="item.post_meta['marca']">{{ item.post_meta['marca'] }}</span>
                          </template>

                          <template v-slot:cell(tamanho)="{ item }">
                            <span v-if="item.post_meta['tamanho']">{{ item.post_meta['tamanho'] }} pés</span>
                          </template>

                          <template v-slot:cell(ano)="{ item }">
                            <span v-if="item.post_meta['ano']">{{ item.post_meta['ano'] }}</span>
                          </template>


                          <template v-slot:cell(motorizacao-quantidade)="{ item }">
                            <span v-if="item.post_meta['motorizacao-quantidade']">{{
                          item.post_meta['motorizacao-quantidade']
                        }}</span>
                          </template>

                          <template v-slot:cell(motorizacao-modelo-potencia)="{ item }">
                            <span v-if="item.post_meta['motorizacao-marca']">{{ item.post_meta['motorizacao-marca']
                              }}</span> <span v-if="item.post_meta['motorizacao-modelo-potencia']">{{
                          item.post_meta['motorizacao-modelo-potencia']
                        }}</span> <span v-if="item.post_meta['motorizacao-combustivel']">{{
                          item.post_meta['motorizacao-combustivel']
                        }}</span>
                          </template>

                          <template v-slot:cell(motorizacao-horas)="{ item }">
                            <span v-if="item.post_meta['motorizacao-horas']">{{ item.post_meta['motorizacao-horas']
                              }}</span>
                          </template>





                          <template v-slot:cell(marina)="{ item }">
                            <span v-if="item.post_meta['marina']">{{ item.post_meta['marina'] }}</span>
                          </template>

                          <template v-slot:cell(valor)="{ item }">
                            <span v-if="item.post_meta['valor']">R${{
                          parseFloat(item.post_meta['valor']).toLocaleString('pt-BR')
                        }}</span>
                          </template>


                          <template v-slot:cell(captador)="{ item }">
                            <span v-if="item.captadores_data || item.author_data['user_name']">{{ item.captadores_data ?
                          item.captadores_data.map(x => x.user_name).join(', ') : item.author_data['user_name']
                              }}</span>
                          </template>




                          <template v-slot:cell(post_status)="{ item }">


                            <span v-if="item.post_status === 'published'" class="text-success"><i
                                class="fa fa-check"></i>
                              Ativado</span>

                            <span v-if="item.post_status === 'pending'" class="text-warning"><i
                                class="fa fa-clock-o"></i>
                              Pendente</span>


                            <span v-if="item.post_status === 'inactive'" class="text-secondary"><i
                                class="fa fa-close"></i>
                              Desativado</span>


                          </template>


                          <template v-slot:cell(actions)="{ item }">
                            <b-link v-if="item.post_status === 'published'"
                              class="action-delete font-weight-bold text-success" @click="copyLink(item)">
                              <i class="fa fa-copy"></i> Link
                            </b-link>
                            <br v-if="item.post_status === 'published'" />


                            <router-link tag="a" class="font-weight-bold action-edit primary"
                              :to="{ path: '/vessels/' + item.id }">
                              <i
                                :class="isUserInCaptadores(userID, item) || userRole == 'administrator' ? 'fa fa-edit' : 'fa fa-eye'"></i>
                              {{ isUserInCaptadores(userID, item) || userRole == 'administrator' ? 'Editar' : 'Ver' }}
                            </router-link>
                            <br />


                            <b-link class="action-delete font-weight-bold text-danger" @click="deleteData(item)"
                              v-if="userRole == 'administrator'">
                              <i class="fa fa-trash"></i> Excluir
                            </b-link>
                          </template>


                          <template v-slot:cell(serialized)="{ item }">
                            <span>{{ JSON.stringify(item) }}</span>
                          </template>


                        </b-table>
                      </div>


                    </div>
                  </div>
                </div>

                <div class="row mt-4">
                  <div class="col-sm-12">
                    <div class="form-group mb-0">



                      <div class="dataLists_paginate paging_simple_numbers">
                        <ul class="pagination pagination-rounded mb-0">
                          <!-- pagination -->
                          <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"></b-pagination>
                        </ul>
                      </div>



                    </div>
                  </div>
                </div>







              </div>
            </div>
          </px-card>
        </div>
      </div>
    </div>
    <!-- Container-fluid Ends-->




    <!-- Container-fluid starts-->
    <div class="container-fluid" v-if="userRole == 'administrator'">
      <div class="row">
        <div class="col-sm-12">
          <px-card>
            <div slot="with-padding">
              <div class="form theme-form">
                <div class="row">
                  <div class="col-sm-3 pt-3">
                    <div class="form-group mb-0">
                      <button type="button" class="btn btn-success mx-1 my-1" @click="exportVessels">{{ "Exportar
                        embarcações para Excel" }}</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </px-card>
        </div>
      </div>
    </div>
    <!-- Container-fluid Ends-->




  </div>
</template>







<script>
import Vue from 'vue'
import moment from 'moment'
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/antd.css'
import { listVessels, deleteVessel, downloadExportedVessels } from '../../data/vessels'
import _ from 'lodash'
/**
 * Advanced table component
 */
export default {
  components: { VueSlider },
  data() {
    return {
      userID: localStorage.getItem("user_id"),
      userRole: localStorage.getItem('user_role'),

      loaded: false,
      dataList: [],
      fullDataList: [],
      title: 'Produtos',
      items: [
        {
          text: 'Home',
          href: '/',
        },
        {
          text: 'Produtos',
          active: true,
        },
      ],
      totalRows: 0,
      currentPage: 1,
      perPage: Vue.$cookies.get('perPageVessels') || 10,
      pageOptions: [10, 25, 50, 100],
      filter: '',
      //filterOn: ['id', 'post_title', 'fabricante', 'modelo', 'ano', 'motorizacao-quantidade', 'motorizacao-modelo-potencia', 'motorizacao-horas', 'marina', 'captador', 'post_date', 'post_status', 'serialized'],
      filterOn: ['id', 'post_title', 'fabricante', 'modelo', 'ano', 'motorizacao-quantidade', 'motorizacao-modelo-potencia', 'motorizacao-horas', 'marina', 'captador', 'post_date', 'post_status'],
      sortBy: Vue.$cookies.get('sortByVessels') || 'name',
      sortDesc: Vue.$cookies.get('sortDescVessels') === 'true' ? true : false,
      fields: [
        { key: 'id', label: 'ID', sortable: true },

        { key: 'media', label: 'Foto', sortable: false },

        { key: 'post_title', label: 'Nome', sortable: true },

        { key: 'fabricante', label: 'Fabricante', sortable: true, formatter: this.formatFabricante, filterByFormatted: true },
        { key: 'modelo', label: 'Modelo', sortable: true, sortByFormatted: true, formatter: this.formatModelo, filterByFormatted: true },
        { key: 'tamanho', label: 'Tamanho', sortable: true, sortByFormatted: true, formatter: this.formatTamanho, filterByFormatted: true },
        { key: 'ano', label: 'Ano', sortable: true, sortByFormatted: true, formatter: this.formatAno, filterByFormatted: true },
        { key: 'motorizacao-quantidade', label: 'Qtd. Motor', sortable: true, sortByFormatted: true, formatter: this.formatMotorizacaoQuantidade, filterByFormatted: true },
        { key: 'motorizacao-modelo-potencia', label: 'Motor', sortable: true, sortByFormatted: true, formatter: this.formatMotorizacaoModeloPotencia, filterByFormatted: true },
        { key: 'motorizacao-horas', label: 'Horas', sortable: true, sortByFormatted: true, formatter: this.formatMotorizacaoHoras, filterByFormatted: true },
        { key: 'marina', label: 'Marina', sortable: true, sortByFormatted: true, formatter: this.formatMarina, filterByFormatted: true },
        { key: 'valor', label: 'Valor', sortable: true, sortByFormatted: true, formatter: this.formatValor, filterByFormatted: true },
        { key: 'captador', label: 'Captador', sortable: true, sortByFormatted: true, formatter: this.formatCaptador, filterByFormatted: true },

        { key: 'post_date', label: 'Criado em', sortable: true, formatter: this.formatDate, filterByFormatted: true },
        { key: 'post_status', label: 'Status', sortable: true, formatter: this.formatStatus, filterByFormatted: false },

        { key: 'actions', label: 'Ações' },
        { key: 'serialized', label: 'Serialized', sortable: true, formatter: this.formatSerialize, filterByFormatted: true, thClass: 'd-none', tdClass: 'd-none' },
      ],
      fValorMin: null,
      fValorMax: null,
      fTamanhoMin: null,
      fTamanhoMax: null,
      fStatus: null,
      statusOptions: { 'published+pending': 'Ativados e Pendentes', 'published': 'Ativados', 'pending': 'Pendentes', 'inactive': 'Desativados', 'all': 'Todos' }
    }
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.totalRows || this.dataList.length
    },
  },
  mounted() {
    // Set the initial number of items
    this.fStatus = this.$route.params.filter ? this.$route.params.filter : 'published+pending'

    this.totalRows = this.dataList.length
    this.loader = this.$loading.show()
    this.loaded = false
    this.getDataList()
  },
  methods: {
    async getDataList() {
      this.fullDataList = await listVessels()


      this.dataList = [...this.fullDataList]


      /* this.max = Math.max.apply(
        Math,
        this.dataList.map(function (o) {
          return o.post_meta && o.post_meta['valor'] ? parseFloat(o.post_meta['valor']) : 0
        })
      )
      this.fvalues = [0, (this.max != "-Infinity" ? this.max : 0)]

      this.maxTamanho = Math.max.apply(
        Math,
        this.dataList.map(function (o) {
          return o.post_meta && o.post_meta['tamanho'] ? parseFloat(o.post_meta['tamanho']) : 0
        })
      )
      this.fvaluesTamanho = [0, (this.maxTamanho != "-Infinity" ? this.maxTamanho : 0)] */


      this.statusFilterChanged();


      this.loaded = true
      setTimeout(() => {
        this.loader.hide()
      }, 500)
    },
    async deleteData(item) {
      if (confirm('Tem certeza de que deseja excluir ' + item.post_title + '?') === true) {
        if ((await deleteVessel(item)) > 0) {
          let idx = this.dataList.indexOf(item)
          this.dataList.splice(idx, 1)
        }
      }
    },


    formatFabricante(value, column, data) {
      return data.post_meta['fabricante']
    },

    formatModelo(value, column, data) {
      return data.post_meta['modelo']
    },

    formatTamanho(value, column, data) {
      return data.post_meta['tamanho']
    },

    formatAno(value, column, data) {
      return data.post_meta['ano']
    },

    formatMotorizacaoQuantidade(value, column, data) {
      return data.post_meta['motorizacao-quantidade']
    },

    formatMotorizacaoModeloPotencia(value, column, data) {
      return data.post_meta['motorizacao-modelo-potencia']
    },

    formatMotorizacaoHoras(value, column, data) {
      return data.post_meta['motorizacao-horas']
    },

    formatMarina(value, column, data) {
      return data.post_meta['marina']
    },

    formatValor(value, column, data) {
      return data.post_meta['valor']
    },

    formatCaptador(value, column, data) {
      //return data.author_data['user_name']
      return data.captadores_data ? data.captadores_data.map(x => x.user_name).join(', ') : data.author_data['user_name']
    },

    normalizeString(str) {
      str.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")
      str = str.replace(/[^a-z0-9/]/gi, ' ')
      return str
    },
    formatSerialize(value, column, data) {
      var str = JSON.stringify(data)
      return this.normalizeString(str)

    },
    formatStatus(value) {

      switch (value) {
        case 'published': return 'Ativado';
        case 'pending': return 'Pendente';
        case 'inactive': return 'Desativado';
      }

    },
    formatDate(value) {
      try {
        //return moment(value).format('DD/MM/YYYY @ H:mm')
        return moment(value).format('DD/MM/YY')
      } catch (e) {
        return null
      }
    },
    sortCompare(a, b, key) {
      /* if (key === 'post_date') {
        // Assuming the date field is a `Date` object, subtraction
        // works on the date serial number (epoch value)
        //return a[key] - b[key]
      } else {
        // Let b-table handle sorting other fields (other than `date` field)
        return false
      } */
    },
    /**
     * Search the table data with search input
     */
    filterTable: function (tableRow, filter) {

      /* var stripedItem = { ...tableRow }
            
      stripedItem.author_data = stripedItem.author_data.user_name;

      //Removing 0 or nulled fields from striped item
      stripedItem.post_meta = Object.keys(stripedItem.post_meta)
        .filter(key => stripedItem.post_meta[key] !== null && stripedItem.post_meta[key] !== 0)
        .reduce((acc, key) => {
          acc[key] = stripedItem.post_meta[key];
          return acc;
        }, {}); */

      var tRow = { ...tableRow };
      var stripedItem = {
        id: tRow.id,
        post_title: tRow.post_title,
        post_date: tRow.post_date,
        post_meta: {
          'ano': tRow.post_meta.ano,
          'fabricante': tRow.post_meta.fabricante,
          'keywords': tRow.post_meta.keywords,
          'marina': tRow.post_meta.marina,
          'modelo': tRow.post_meta.modelo,
          'motorizacao-combustivel': tRow.post_meta['motorizacao-combustivel'],
          'motorizacao-horas': tRow.post_meta['motorizacao-horas'],
          'motorizacao-marca': tRow.post_meta['motorizacao-marca'],
          'motorizacao-modelo-potencia': tRow.post_meta['motorizacao-modelo-potencia'],
          'motorizacao-quantidade': tRow.post_meta['motorizacao-quantidade']
        },
        captadores_data: [],
      }

      if (tRow.captadores_data) {
        tRow.captadores_data.forEach((item, index) => {
          stripedItem.captadores_data.push({ user_name: item.user_name })
        })
      }


      var serialized = JSON.stringify(stripedItem)
      serialized = this.normalizeString(serialized).toLowerCase()

      var match = true;

      if (filter) {
        var wordsFilter = filter.split(" ");
        if (!wordsFilter.every(word => serialized.includes(this.normalizeString(word).toLowerCase()))) {
          match = false;
        }
      }

      if (match === true) {
        //console.log('tableRow', tableRow, serialized);
        //console.log('filterTable', stripedItem, serialized);
        return tableRow;
      } else {
        return null;
      }

    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    debounceInput: _.debounce(function (e) {
      var val = this.normalizeString(e.target.value)
      this.filter = val
    }, 250),
    savePerPage(e) {
      Vue.$cookies.set('perPageVessels', e)
    },
    onSort(e) {
      Vue.$cookies.set('sortByVessels', e.sortBy)
      Vue.$cookies.set('sortDescVessels', e.sortDesc)
    },
    /* valorFilterChanged(val, field) {

      if (field === 'fValorMin') this.fValorMin = val ? parseFloat(val) : null;
      if (field === 'fValorMax') this.fValorMax = val ? parseFloat(val) : null;

      this.dataList = [...this.fullDataList]
      var removeIndexes = []


      this.dataList.forEach((item, index) => {

        var valor = item['post_meta'] && item['post_meta']['valor'] ? parseFloat(item['post_meta']['valor']) : 0
        if (this.fValorMin && valor < this.fValorMin) {
          removeIndexes.push(index)
        }
        if (this.fValorMax && valor > this.fValorMax) {
          removeIndexes.push(index)
        }

      })

      this.dataList = this.dataList.filter(function (value, index) {
        return removeIndexes.indexOf(index) == -1
      })
      this.onFiltered(this.dataList)


    }, */
    /* tamanhoFilterChanged(val, field) {
    
      if (field === 'fTamanhoMin') this.fTamanhoMin = val ? parseFloat(val) : null;
      if (field === 'fTamanhoMax') this.fTamanhoMax = val ? parseFloat(val) : null;

      this.dataList = [...this.fullDataList]
      var removeIndexes = []


      this.dataList.forEach((item, index) => {

        
        var tamanho = item['post_meta'] && item['post_meta']['tamanho'] ? parseFloat(item['post_meta']['tamanho']) : 0
        if (this.fTamanhoMin && tamanho < this.fTamanhoMin) {
          removeIndexes.push(index)
        }
        if (this.fTamanhoMax && tamanho > this.fTamanhoMax) {
          removeIndexes.push(index)
        }

      })

      this.dataList = this.dataList.filter(function (value, index) {
        return removeIndexes.indexOf(index) == -1
      })
      this.onFiltered(this.dataList)

    }, */
    extraFilterChanged(val, field) {

      if (field === 'fValorMin') this.fValorMin = val ? parseFloat(val) : null;
      if (field === 'fValorMax') this.fValorMax = val ? parseFloat(val) : null;

      if (field === 'fTamanhoMin') this.fTamanhoMin = val ? parseFloat(val) : null;
      if (field === 'fTamanhoMax') this.fTamanhoMax = val ? parseFloat(val) : null;

      this.dataList = [...this.fullDataList]
      var removeIndexes = []


      this.dataList.forEach((item, index) => {


        var valor = item['post_meta'] && item['post_meta']['valor'] ? parseFloat(item['post_meta']['valor']) : 0
        if (this.fValorMin && valor < this.fValorMin) {
          removeIndexes.push(index)
        }
        if (this.fValorMax && valor > this.fValorMax) {
          removeIndexes.push(index)
        }

        var tamanho = item['post_meta'] && item['post_meta']['tamanho'] ? parseFloat(item['post_meta']['tamanho']) : 0
        if (this.fTamanhoMin && tamanho < this.fTamanhoMin) {
          removeIndexes.push(index)
        }
        if (this.fTamanhoMax && tamanho > this.fTamanhoMax) {
          removeIndexes.push(index)
        }

      })

      this.dataList = this.dataList.filter(function (value, index) {
        return removeIndexes.indexOf(index) == -1
      })
      this.onFiltered(this.dataList)

    },

    statusFilterChanged() {


      this.dataList = [...this.fullDataList]
      var removeIndexes = []

      console.log('statusFilterChanged', this.fStatus, this.dataList);


      

      if (this.fStatus === 'published+pending') {
        this.dataList.forEach((item, index) => {
          if (item.post_status !== 'published' && item.post_status !== 'pending') {
            removeIndexes.push(index)
          }
        })
      }
      else if (this.fStatus !== 'all'){
        this.dataList.forEach((item, index) => {
          if (this.fStatus !== item.post_status) {
            removeIndexes.push(index)
          }
        })
      }

      this.dataList = this.dataList.filter(function (value, index) {
        return removeIndexes.indexOf(index) == -1
      })
      this.onFiltered(this.dataList)

    },

    copyLink(item) {
      navigator.clipboard.writeText('https://crm.internauticaguaruja.com.br/v/' + item.id + '/' + item["post_name"]);
      this.$toasted.show(' Link copiado com sucesso ', { theme: 'outline', position: 'top-right', type: 'success', icon: 'check', duration: 5000 })
    },
    exportVessels() {
      downloadExportedVessels();
    },
    isUserInCaptadores(userID, item) {
      var captadores = item.post_meta.captadores ? item.post_meta.captadores.split(';') : [];
      return captadores.includes(userID.toString());
    }
  },
}
</script>