<template>
  <div class="container-fluid">


    <div class="row">
      <div class="col-xl-12 p-3 text-center"
        style="height: 100vh; align-items: center; justify-content: center; display: flex; flex: 1; flex-direction: column;">

        <img class="img-fluid d-none d-xl-block" src="../assets/images/logo/logo-crm-dark.png" alt
          style="height: 100px;" />

        <h1 class="my-3" style="font-size:25vh;">404</h1>
        <h3 style="">Oops! Página não encontrada...</h3>

        <router-link tag="a" class="btn btn-primary mt-3" :to="{ path: '/' }">
          Início
        </router-link>

      </div>
    </div>
  </div>
</template>


<script>


export default {

  components: {
  },
  data() {
    return {


    };
  },
  computed: {

  },
  mounted() {

    this.loader = this.$loading.show()

    
    setTimeout(() => {
      this.loader.hide()
    }, 1000)
  },
  created() {


  },
  methods: {

  },
};
</script>

<style>

</style>
