import axios from 'axios';

export async function listNotifications() {

  var list = null

  var post_args = {
    post_type: 'notifications',
    post_status: 'published',
    order_by: 'id DESC',
  }

  var meta_args = {

    relation: 'OR',
    args: [
      {
        meta_key: 'recipients',
        compare: 'LIKE',
        meta_value: '%"' + localStorage.getItem("user_id") + '"%'
        //meta_value: '%\"' + localStorage.getItem("user_id") + '\"%'
      }
    ]

  }

  var params = {
    user: localStorage.getItem("user_id"),
    post_args,
    meta_args
  }

  /* var formdata = new FormData()
  formdata.append("user", localStorage.getItem("user_id"))
  formdata.append("post_args", post_args)
  formdata.append("meta_args", meta_args) */

  var token = localStorage.getItem("user_jwt")
  var config = { headers: { Authorization: `Bearer ${token}` } }

  await axios
    .post(
      "https://crm.internauticaguaruja.com.br/api/get.posts.php?source=webapp",
      params,
      config
    )
    .then((response) => {
      list = response.data
    })
    .catch((error) => {
      console.log("error", error)
    });

  return list

}





export async function readNotification(id, postData, metaData) {

  var res = null;

  //postData['post_type'] = 'vessels'

  var params = {
    user: localStorage.getItem("user_id"),
    id,
    post_data: postData,
    post_meta: metaData
  }

  var token = localStorage.getItem("user_jwt")
  var config = { headers: { Authorization: `Bearer ${token}`, 'Content-type': 'multipart/form-data' } }



  await axios
    .post(
      "https://crm.internauticaguaruja.com.br/api/update.post.php?source=webapp",
      params,
      config
    )
    .then((response) => {
      res = response.data
    })
    .catch((error) => {
      console.log("error", error)
    });

  return res

}




