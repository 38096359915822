import Vue from 'vue'
import App from './App.vue'
import BootstrapVue from 'bootstrap-vue'
import router from './router'
import Breadcrumbs from './components/bread_crumbs'
import { store } from './store';
import VueFeather from 'vue-feather';

import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

import Toasted from 'vue-toasted';
Vue.use(Toasted,{
  iconPack: 'fontawesome'
});

Vue.use(require('vue-cookies'))
Vue.$cookies.config(60 * 60 * 24 * 365 * 10)


// Init plugin
Vue.use(Loading, {
  color: '#1d4791',
  loader: 'spinner',
  width: 64,
  height: 64,
  backgroundColor: '#000000',
  opacity: 0.5,
  zIndex: 999,
});

import axios from 'axios'
import VueAxios from 'vue-axios'
Vue.use(VueAxios, axios)

import PxCard  from './components/Pxcard.vue'
Vue.component(PxCard.name, PxCard)

// Import Theme scss
import './assets/scss/app.scss'


Vue.use(VueFeather);
Vue.use(BootstrapVue)
Vue.component('Breadcrumbs', Breadcrumbs)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')