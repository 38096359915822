<template>
  <div>
    <Breadcrumbs title="Relatórios" />

    <!-- Container-fluid starts-->
    <div class="container-fluid">
      <div class="row">
        <div class="col-sm-12">
          <px-card>
            <div slot="with-padding">
              <div class="form theme-form">
                <div class="row">
                  <div class="col-sm-3 pt-3">
                    <div class="form-group mb-0">
                      <label class="d-inline-flex align-items-center w-100">
                        Filtrar:
                        <b-form-input id="filter-input" :model="filter" :value="filter" type="search"
                          placeholder="Digite para procurar..." class="form-control form-control-sm ml-2"
                          @keyup="debounceInput" @click="debounceInput">
                        </b-form-input>
                      </label>
                    </div>
                  </div>
                  <div class="col-sm-3 pt-3">

                  </div>
                  <div class="col-sm-3 pt-3">

                  </div>
                  <div class="col-sm-3 text-right  pt-3">
                    <div class="form-group mb-0">
                      <label class="d-inline-flex align-items-center">
                        Exibir&nbsp;
                        <b-form-select v-model="perPage" size="sm" :options="pageOptions" @change="savePerPage">
                        </b-form-select>&nbsp;registros
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </px-card>
        </div>
      </div>
    </div>
    <!-- Container-fluid Ends-->

    <!-- Container-fluid starts-->
    <div class="container-fluid">
      <div class="row">
        <div class="col-sm-12">
          <px-card>
            <div slot="with-padding">
              <div class="form theme-form">
                <div class="row">
                  <div class="col-sm-12">
                    <div class="form-group mb-0">

                      <div class="table-responsive mb-0">
                        <b-table class="table-striped mb-0" style="font-size: 12px" :items="dataList" :fields="fields"
                          responsive="sm" :per-page="perPage" :current-page="currentPage" :sort-by.sync="sortBy"
                          :sort-desc.sync="sortDesc" :sort-compare="sortCompare" :filter="filter"
                          :filter-included-fields="filterOn" @filtered="onFiltered" @sort-changed="onSort">





                          <template v-slot:cell(captador)="{ item }">
                            <span v-if="item.author_data['user_name']">{{ item.author_data['user_name'] }}</span>
                          </template>


                          <template v-slot:cell(vessel)="{ item }">
                            <router-link tag="a" v-if="item.post_title" class="font-weight-bold action-edit primary"
                              :to="{ path: '/vessels/' + item.id }">
                              {{ item.post_meta['fabricante'] + " - " + item.post_meta['modelo'] + " - " + item['post_title'] }}
                            </router-link>
                          </template>



                          <template v-slot:cell(post_status)="{ item }">

                            <span v-if="item.post_status === 'published'" class="text-success"><i
                                class="fa fa-check"></i>
                              Ativado</span>

                            <span v-if="item.post_status === 'pending'" class="text-warning"><i
                                class="fa fa-clock-o"></i>
                              Pendente</span>


                            <span v-if="item.post_status === 'inactive'" class="text-secondary"><i
                                class="fa fa-close"></i>
                              Desativado</span>

                          </template>



                          <template v-slot:cell(serialized)="{ item }">
                            <span>{{ JSON.stringify(item) }}</span>
                          </template>


                        </b-table>
                      </div>


                    </div>
                  </div>
                </div>

                <div class="row mt-4">
                  <div class="col-sm-12">
                    <div class="form-group mb-0">



                      <div class="dataLists_paginate paging_simple_numbers">
                        <ul class="pagination pagination-rounded mb-0">
                          <!-- pagination -->
                          <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"></b-pagination>
                        </ul>
                      </div>



                    </div>
                  </div>
                </div>







              </div>
            </div>
          </px-card>
        </div>
      </div>
    </div>
    <!-- Container-fluid Ends-->




  </div>
</template>







<script>
import Vue from 'vue'
import moment from 'moment'
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/antd.css'
import { listVessels } from '../data/vessels'
import _ from 'lodash'
/**
 * Advanced table component
 */
export default {
  components: { VueSlider },
  data() {
    return {
      userID: localStorage.getItem("user_id"),
      userRole: localStorage.getItem('user_role'),
      loaded: false,
      dataList: [],
      fullDataList: [],
      title: 'Produtos',
      items: [
        {
          text: 'Home',
          href: '/',
        },
        {
          text: 'Produtos',
          active: true,
        },
      ],
      totalRows: 0,
      currentPage: 1,
      perPage: Vue.$cookies.get('perPageReports') || 10,
      pageOptions: [10, 25, 50, 100],
      filter: '',
      filterOn: ['captacao-proprietario', 'captacao-proprietario-tel', 'captacao-proprietario-email', 'vessel', 'captador', 'post_date', 'post_status', 'serialized'],
      sortBy: Vue.$cookies.get('sortByReports') || 'name',
      sortDesc: Vue.$cookies.get('sortDescReportss') === 'true' ? true : false,
      fields: [

        { key: 'post_date', label: 'Criado em', sortable: true, formatter: this.formatDate, filterByFormatted: true },
        { key: 'captacao-proprietario', label: 'Proprietário', sortable: true, sortByFormatted: true, formatter: this.formatProprietario },
        { key: 'captacao-proprietario-tel', label: 'Tel.', sortable: true, sortByFormatted: true, formatter: this.formatProprietarioTel },
        { key: 'captacao-proprietario-email', label: 'Email', sortable: true, sortByFormatted: true, formatter: this.formatProprietarioEmail },
        { key: 'vessel', label: 'Embarcação', sortable: true, formatter: this.formatEmbarcacao },
        { key: 'captador', label: 'Captador', sortable: true, sortByFormatted: true, formatter: this.formatCaptador },
        { key: 'post_status', label: 'Status', sortable: true, formatter: this.formatStatus, filterByFormatted: true },
        { key: 'serialized', label: 'Serialized', sortable: true, formatter: this.formatSerialize, filterByFormatted: true, thClass: 'd-none', tdClass: 'd-none' },
      ],
    }
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.totalRows || this.dataList.length
    },
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.dataList.length
    this.loader = this.$loading.show()
    this.loaded = false
    this.getDataList()


  },
  methods: {
    async getDataList() {
      this.fullDataList = await listVessels()


      this.dataList = [...this.fullDataList]



      this.loaded = true
      setTimeout(() => {
        this.loader.hide()
      }, 500)
    },


    formatProprietario(value, column, data) {
      return data.post_meta['captacao-proprietario']
    },

    formatProprietarioTel(value, column, data) {
      return data.post_meta['captacao-proprietario-tel']
    },

    formatProprietarioEmail(value, column, data) {
      return data.post_meta['captacao-proprietario-email']
    },



    formatEmbarcacao(value, column, data) {
      return data.post_meta['fabricante'] + " - " + data.post_meta['modelo'] + " - " + data['post_title']
    },

    formatCaptador(value, column, data) {
      return data.author_data['user_name']
    },

    normalizeString(str) {
      str.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")
      str = str.replace(/[^a-z0-9/]/gi, ' ')
      return str
    },
    formatSerialize(value, column, data) {
      var str = JSON.stringify(data)
      return this.normalizeString(str)

    },
    formatStatus(value) {

      switch (value) {
        case 'published': return 'Ativado';
        case 'pending': return 'Pendente';
        case 'inactive': return 'Desativado';
      }

    },
    formatDate(value) {
      try {
        //return moment(value).format('DD/MM/YYYY @ H:mm')
        return moment(value).format('DD/MM/YY')
      } catch (e) {
        return null
      }
    },
    sortCompare(a, b, key) {
      /* if (key === 'post_date') {
        // Assuming the date field is a `Date` object, subtraction
        // works on the date serial number (epoch value)
        //return a[key] - b[key]
      } else {
        // Let b-table handle sorting other fields (other than `date` field)
        return false
      } */
    },
    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    debounceInput: _.debounce(function (e) {
      var val = this.normalizeString(e.target.value)
      this.filter = val
    }, 250),
    savePerPage(e) {
      Vue.$cookies.set('perPageReports', e)
    },
    onSort(e) {
      Vue.$cookies.set('sortByReports', e.sortBy)
      Vue.$cookies.set('sortDescReports', e.sortDesc)
    }
  },
}
</script>