import axios from 'axios';

export async function getStats() {

  var stats = null

  var post_args = {
    post_type: 'vessels',
  }

  var meta_args = {

  }

  var params = {
    user: localStorage.getItem("user_id"),
    post_args,
    meta_args
  }

  var token = localStorage.getItem("user_jwt")
  var config = { headers: { Authorization: `Bearer ${token}` } }

  await axios
    .post(
      "https://crm.internauticaguaruja.com.br/api/get.stats.php?source=webapp",
      params,
      config
    )
    .then((response) => {
      stats = response.data
    })
    .catch((error) => {
    });

  return stats

}













