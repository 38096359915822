import Vue from 'vue'
import Router from "vue-router";

import Body from '../components/body'
import Auth from "../components/auth";
import Main from "../components/main";

import V from "../pages/v";
import NotFound from "../pages/404";

import Login from "../pages/login";
import Dashboard from '../pages/dashboard'

import ListVessels from "../pages/vessels/list-vessels"
import EditVessel from "../pages/vessels/edit-vessel"

import Reports from "../pages/reports"


import ListUsers from "../pages/users/list-users"
import EditUser from "../pages/users/edit-user"



// component

Vue.use(Router)

const routes = [
  { path: '', redirect: { name: 'default' } },
  {
    path: '/dashboard',
    component: Body,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: '',
        name: 'default',
        component: Dashboard,
        meta: {
          title: 'Dashboard | CRM - Internáutica',
        }
      },
    ]
  },
  {
    path: '/profile',
    component: Body,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: '',
        name: 'profile',
        component: EditUser,
        meta: {
          title: 'Perfil | CRM - Internáutica',
        }
      },
    ]
  },
  {
    path: "/vessels",
    component: Body,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: "list/:filter?",
        name: "vessels",
        component: ListVessels,
        meta: {
          title: "Embarcações | CRM - Internáutica",
        },
      },
      {
        path: "create",
        name: "create",
        component: EditVessel,
        meta: {
          title: "Criar Embarcação | CRM - Internáutica",
        },
      },
      {
        path: ":id",
        name: "edit",
        component: EditVessel,
        meta: {
          title: "Editar Embarcação | CRM - Internáutica",
        },
      },
    ],
  },
  {
    path: "/reports",
    component: Body,
    meta: {
      requiresAuth: true,
      requiresAdmin: true,
    },
    children: [
      {
        path: "",
        name: "reports",
        component: Reports,
        meta: {
          title: "Relatórios | CRM - Internáutica",
          requiresAuth: true,
          requiresAdmin: true,
        },
      },
    ],
  },
  {
    path: "/users",
    component: Body,
    meta: {
      requiresAuth: true,
      requiresAdmin: true,
    },
    children: [
      {
        path: "list",
        name: "users",
        component: ListUsers,
        meta: {
          title: "Usuários | CRM - Internáutica",
          requiresAuth: true,
          requiresAdmin: true,
        },
      },
      {
        path: "create",
        name: "create",
        component: EditUser,
        meta: {
          title: "Criar Usuário | CRM - Internáutica",
          requiresAuth: true,
          requiresAdmin: true,
        },
      },
      {
        path: ":id",
        name: "edit",
        component: EditUser,
        meta: {
          title: "Editar Usuário | CRM - Internáutica",
          requiresAuth: true,
          requiresAdmin: true,
        },
      },
    ],
  },
  {
    path: '/404',
    component: Main,
    children: [
      {
        path: "",
        name: "NotFound",
        component: NotFound,
        meta: {
          title: "CRM - Internáutica",
        },
      }
    ],
  },
  {
    path: '/v',
    component: Main,
    children: [
      {
        path: ":id/:post_name",
        name: "V",
        component: V,
        meta: {
          title: "CRM - Internáutica",
        },
      }
    ],
  },
  {
    path: '/',
    redirect: 'login',
    component: Auth,
    children: [
      {
        path: "login",
        name: "Login",
        component: Login,
        meta: {
          title: "Login | CRM - Internáutica",
        },
      }
    ],
  }];

const router = new Router({
  routes,
  base: '/',
  mode: 'history',
  linkActiveClass: "active",
  scrollBehavior() {
    return { x: 0, y: 0 }
  }
});

import axios from 'axios';

router.beforeEach(async (to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {


    var params = {
      user: localStorage.getItem("user_id"),
    }
    var token = localStorage.getItem("user_jwt")
    var config = { headers: { Authorization: `Bearer ${token}` } }


    var auth = await axios
      .post(
        "https://crm.internauticaguaruja.com.br/api/auth.check.php?source=webapp",
        params,
        config
      )
      .then((response) => {
        return response.data === 'Ok' ? true : false
      })
      .catch((error) => {
        return false
      });

    if (to.matched.some(record => record.meta.requiresAdmin)) {
      if (!auth || localStorage.getItem('user_jwt') == null || localStorage.getItem('user_role') !== 'administrator') {
        next({
          path: '/login',
          params: { nextUrl: to.fullPath }
        })
      } else {
        next()
      }
    }
    else {
      if (!auth || localStorage.getItem('user_jwt') == null) {
        next({
          path: '/login',
          params: { nextUrl: to.fullPath }
        })
      } else {
        next()
      }
    }
  } else {
    next()
  }
})

export default router
