<template>
  <div>
    <!-- page-wrapper Start-->
    <div class="container-fluid p-0">
      <div class="row m-0">
        <div class="col-12 p-0">
          <div class="login-card">
            <div>

              <div class="login-main">
                <b-card>
                  <b-card-text>
                    <a class="logo mx-auto mb-4 w-75">
                      <img class="img-fluid" src="../assets/images/logo/logo-crm-dark.png" alt="looginpage" />
                    </a>
                    <h4 class="text-center">Entre na sua conta</h4>
                    <p class="text-center">Preencha os seus dados de acesso para entrar</p>
                    <form class="theme-form" @submit.prevent="handleSubmit">
                      <div class="form-group">
                        <label for="username">Usuário</label>
                        <input type="text" v-model="username" name="username" class="form-control"
                          :class="{ 'is-invalid': submitted && !username }" />
                        <div v-show="submitted && !username" class="invalid-feedback">
                          Usuário obrigatório
                        </div>
                      </div>
                      <div class="form-group">
                        <label htmlFor="password">Senha</label>
                        <input :type="type" v-model="password" name="password" class="form-control" :class="{
                          'is-invalid': submitted && !password,
                        }" />
                        <div v-show="submitted && !password" class="invalid-feedback">
                          Senha obrigatória
                        </div>
                        <div class="show-hide" @click="showPassword">
                          <!-- <span class="show"></span> -->
                          <i class="fa fa-eye-slash"></i>
                        </div>
                      </div>






                      <div class="form-group mt-3 mb-0">
                        <button class="btn btn-primary btn-block" :disabled="loggingIn">
                          Entrar
                        </button>
                      </div>

                      <div v-if="this.error" class="form-group mt-3 mb-0 text-center">
                        <b-badge variant="danger">{{ this.error }}</b-badge>
                      </div>



                    </form>
                  </b-card-text>
                </b-card>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- latest jquery-->
  </div>
</template>

<script>

var SHA256 = require("crypto-js/sha256");

export default {
  name: 'login',
  data() {
    return {
      type: 'password',
      username: '',
      password: '',
      submitted: false,
      error: ''
    };
  },
  computed: {
    // JWT authentication
    loggingIn() {
      //return this.$store.state.authentication.status.loggingIn;
    },
  },
  created() {
    // reset login status for JWT
    //this.$store.dispatch('authentication/logout');
  },
  methods: {
    showPassword: function () {
      if (this.type === 'password') {
        this.type = 'text';
      } else {
        this.type = 'password';
      }
    },
    async handleSubmit() {

      this.loader = this.$loading.show();


      const hash = SHA256(this.password);

      var data = new FormData();
      data.append("username", this.username);
      data.append("password", hash.toString());



      this.axios
        .post(
          "https://crm.internauticaguaruja.com.br/api/signin.php",
          data
        )
        .then((response) => {

          if (response.data.length > 0) {
            localStorage.setItem('user', JSON.stringify(response.data[0]));
            localStorage.setItem("user_id", response.data[0].id);
            localStorage.setItem("user_name", response.data[0].user_name);
            localStorage.setItem("user_role", response.data[0].user_role);
            localStorage.setItem("user_jwt", response.data[0].jwt);

            this.$router.replace("/dashboard");

          } else {
            this.error = "Usuário ou senha inválidos";
            localStorage.removeItem("user");
          }

          setTimeout(() => {
            this.loader.hide();
          }, 500);


        })
        .catch((error) => {
          //this.error = "Ops! Ocorreu um erro ao entrar...";
          console.log("error", error);

          setTimeout(() => {
            this.loader.hide();
          }, 500);
        });
    },
  },
};
</script>
