import axios from 'axios';

export async function listVessels() {

  var list = null

  var post_args = {
    post_type: 'vessels',
  }

  var meta_args = {


  }

  var params = {
    user: localStorage.getItem("user_id"),
    post_args,
    meta_args
  }

  /* var formdata = new FormData()
  formdata.append("user", localStorage.getItem("user_id"))
  formdata.append("post_args", post_args)
  formdata.append("meta_args", meta_args) */

  var token = localStorage.getItem("user_jwt")
  var config = { headers: { Authorization: `Bearer ${token}` } }

  await axios
    .post(
      "https://crm.internauticaguaruja.com.br/api/get.posts.php?source=webapp",
      params,
      config
    )
    .then((response) => {
      list = response.data
    })
    .catch((error) => {
      console.log("error", error)
    });


  return list

}
















export async function getVessel(id) {

  var data = null

  var post_args = {
    id,
    post_type: 'vessels'
  }

  var params = {
    user: localStorage.getItem("user_id"),
    post_args: post_args
  }

  /* var formdata = new FormData()
  formdata.append("source", "webapp")
  formdata.append("user", localStorage.getItem("user_id"))
  formdata.append("post_args", post_args) */

  var token = localStorage.getItem("user_jwt")
  var config = { headers: { Authorization: `Bearer ${token}`, 'Content-type': 'multipart/form-data' } }



  await axios
    .post(
      "https://crm.internauticaguaruja.com.br/api/get.posts.php?source=webapp",
      params,
      config
    )
    .then((response) => {

      if (response.data.length > 0) {
        data = response.data[0]
      }

    })
    .catch((error) => {
      console.log("error", error)
    });

  return data
}

export async function updateVessel(id, postData, metaData) {

  var res = null;

  //postData['post_type'] = 'vessels'

  var params = {
    user: localStorage.getItem("user_id"),
    id,
    post_data: postData,
    post_meta: metaData
  }

  var token = localStorage.getItem("user_jwt")
  var config = { headers: { Authorization: `Bearer ${token}`, 'Content-type': 'multipart/form-data' } }


  await axios
    .post(
      "https://crm.internauticaguaruja.com.br/api/update.post.php?source=webapp",
      params,
      config
    )
    .then((response) => {
      res = response.data
    })
    .catch((error) => {
      console.log("error", error)
    });

  return res

}

export async function createVessel(postData, metaData, notify) {

  var res = null;

  postData['post_type'] = 'vessels'

  var params = {
    user: localStorage.getItem("user_id"),
    post_data: postData,
    post_meta: metaData,
    notification: (notify === true ? 'new-vessel' : '')
  }

  var token = localStorage.getItem("user_jwt")
  var config = { headers: { Authorization: `Bearer ${token}`, 'Content-type': 'multipart/form-data' } }

  await axios
    .post(
      "https://crm.internauticaguaruja.com.br/api/add.post.php?source=webapp",
      params,
      config
    )
    .then((response) => {
      res = response.data

    })
    .catch((error) => {
      console.log("error", error)
    });

  return res


}


export async function deleteMedia(id, media) {

  var res = null;

  //postData['post_type'] = 'vessels'

  var params = {
    user: localStorage.getItem("user_id"),
    id,
    kind: 'posts',
    post_type: 'vessels',
    filepath: media
  }

  var token = localStorage.getItem("user_jwt")
  var config = { headers: { Authorization: `Bearer ${token}`, 'Content-type': 'multipart/form-data' } }

  await axios
    .post(
      "https://crm.internauticaguaruja.com.br/api/delete.file.php?source=webapp",
      params,
      config
    )
    .then((response) => {
      res = response.data
    })
    .catch((error) => {
      console.log("error", error)
    });

  return res

}





export async function deleteAllMedia(id) {

  var res = null;

  //postData['post_type'] = 'vessels'

  var params = {
    user: localStorage.getItem("user_id"),
    id,
    kind: 'posts',
    post_type: 'vessels',
  }

  var token = localStorage.getItem("user_jwt")
  var config = { headers: { Authorization: `Bearer ${token}`, 'Content-type': 'multipart/form-data' } }

  await axios
    .post(
      "https://crm.internauticaguaruja.com.br/api/delete.medias.php?source=webapp",
      params,
      config
    )
    .then((response) => {
      res = response.data
    })
    .catch((error) => {
      console.log("error", error)
    });

  return res

}





export async function deleteVessel(post_data) {

  var res = null;

  //postData['post_type'] = 'vessels'

  var params = {
    user: localStorage.getItem("user_id"),
    post_data
  }

  var token = localStorage.getItem("user_jwt")
  var config = { headers: { Authorization: `Bearer ${token}`, 'Content-type': 'multipart/form-data' } }

  await axios
    .post(
      "https://crm.internauticaguaruja.com.br/api/delete.post.php?source=webapp",
      params,
      config
    )
    .then((response) => {
      res = response.data
    })
    .catch((error) => {
      console.log("error", error)
    });

  return res

}




export async function downloadExportedVessels() {

  var filename = 'exported-embarcacoes-crm-' + new Date().toISOString().split('T')[0] + '.xlsx';


  var post_args = {
    post_type: 'vessels',
  }

  var meta_args = {


  }

  var params = {
    user: localStorage.getItem("user_id"),
    post_args,
    meta_args
  }

  /* var formdata = new FormData()
  formdata.append("user", localStorage.getItem("user_id"))
  formdata.append("post_args", post_args)
  formdata.append("meta_args", meta_args) */

  var token = localStorage.getItem("user_jwt")
  var config = {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Disposition': "attachment; filename=" + filename,
      'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    },
    responseType: 'arraybuffer',
  }

  await axios
    .post(
      "https://crm.internauticaguaruja.com.br/api/export.posts.php?source=webapp",
      params,
      config
    )
    .then((response) => {
      /* const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }); // Defina o tipo do arquivo, se necessário
      // Crie um objeto URL a partir do blob e crie um link para o download
      const downloadLink = document.createElement('a');
      downloadLink.href = window.URL.createObjectURL(blob);
      downloadLink.download = 'exported-vessels.xlsx'; // Nome do arquivo de download
      // Dispare um clique no link para iniciar o download
      downloadLink.click(); */


      // create file link in browser's memory
      const href = window.URL.createObjectURL(new Blob([response.data]));
      
      // create "a" HTML element with href to file & click
      const link = document.createElement('a');
      link.href = href;
      link.setAttribute('download', filename); //or any other extension
      document.body.appendChild(link);
      link.click();

      // clean up "a" element & remove ObjectURL
      document.body.removeChild(link);
      URL.revokeObjectURL(href);

    })
    .catch((error) => {
      console.log("error", error)
    });


  return null

}



