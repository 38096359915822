<template>
  <div>
    <Breadcrumbs main="" title="Dashboard" />
    <!-- Container-fluid starts-->
    <div class="container-fluid">
      <div class="row second-chart-list third-news-update">




        <div class="col-xl-4 col-lg-12 xl-50 morning-sec box-col-12">
          <px-card class="profile-greeting">
            <div slot="with-padding">
              <div class="media">
                <div class="media-body">
                  <div class="greeting-user">
                    <h4 class="f-w-600 font-primary" id="greeting">
                      {{ greeting }}
                    </h4>
                    <p>
                      Confira os principais números
                    </p>

                    <div class="whatsnew-btn w-100 mb-3">
                      <router-link tag="a" class="btn btn-primary py-3" :to="{ path: '/vessels/list' }">
                        Listar embarcações
                      </router-link>
                    </div>

                    <div class="whatsnew-btn w-100">
                      <router-link tag="a" class="btn btn-secondary py-3" :to="{ path: '/vessels/create' }">
                        Adicionar nova embarcação
                      </router-link>
                    </div>

                  </div>
                </div>
                <div class="badge-groups">
                  <div class="badge f-10">
                    <feather type="clock" class="mr-1"></feather>
                    <span id="txt">{{ time }}</span>
                  </div>
                </div>
              </div>
            </div>
          </px-card>
        </div>
        <div class="col-xl-8 xl-100 dashboard-sec box-col-12">
          <px-card class="earning-card">
            <div class="row m-0">
              <div class="col-xl-2 earning-content p-0">
                <div class="row m-0 chart-left">
                  <div class="col-xl-12 p-0 left_side_earning">
                    <h5>Embarcações</h5>
                    <p class="font-roboto">Visão Geral</p>
                  </div>
                </div>
              </div>
              <div class="col-xl-10 p-0">

                <div class="row border-top m-0">
                  <div class="col-xl-3 pl-0 col-md-6 col-sm-6">
                    <div class="media p-0">
                      <div class="media-left" @click="() => { this.$router.push({ path: `/vessels/list/all` }) }">
                        <i class="icofont icofont-ship"></i>
                      </div>
                      <div class="media-body">
                        <h6>Total</h6>
                        <p>{{ stats.total_posts }}</p>
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-3 col-md-6 col-sm-6">
                    <div class="media p-0">
                      <div class="media-left bg-success" @click="() => { this.$router.push({ path: `/vessels/list/published` }) }">
                        <i class="icofont icofont-ship"></i>
                      </div>
                      <div class="media-body">
                        <h6>Ativas</h6>
                        <p>{{ stats.published_posts }}</p>
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-3 col-md-12 pr-0">
                    <div class="media p-0">
                      <div class="media-left bg-warning" @click="() => { this.$router.push({ path: `/vessels/list/pending` }) }">
                        <i class="icofont icofont-ship"></i>
                      </div>
                      <div class="media-body">
                        <h6>Pendentes</h6>
                        <p>{{ stats.pending_posts }}</p>
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-3 col-md-12 pr-0">
                    <div class="media p-0">
                      <div class="media-left bg-secondary" @click="() => { this.$router.push({ path: `/vessels/list/inactive` }) }">
                        <i class="icofont icofont-ship"></i>
                      </div>
                      <div class="media-body">
                        <h6>Desativadas</h6>
                        <p>{{ stats.inactive_posts }}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </px-card>
        </div>






      </div>
    </div>
    <!-- Container-fluid Ends-->
  </div>
</template>
<script>
import { getStats } from '../data/stats'

export default {
  data() {
    return {
      greeting: '',
      time: '',
      stats: []
    };
  },
  mounted() {
    var d = new Date();
    var curHr = this.addZero(d.getHours());
    var curMi = this.addZero(d.getMinutes());
    var meridiem = curHr >= 12 ? 'PM' : 'AM';
    this.time = curHr + ':' + curMi + ' ' + meridiem;
    if (curHr < 12) {
      this.greeting = 'Bom dia!';
    } else if (curHr < 18) {
      this.greeting = 'Boa tarde!';
    } else {
      this.greeting = 'Boa noite!';
    }
    this.loadStats();
  },
  methods: {

    async loadStats() {
      this.stats = await getStats();
    },

    addZero(i) {
      if (i < 10) {
        i = '0' + i;
      }
      return i;
    }
  },
};
</script>
