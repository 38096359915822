var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"container-fluid p-0"},[_c('div',{staticClass:"row m-0"},[_c('div',{staticClass:"col-12 p-0"},[_c('div',{staticClass:"login-card"},[_c('div',[_c('div',{staticClass:"login-main"},[_c('b-card',[_c('b-card-text',[_c('a',{staticClass:"logo mx-auto mb-4 w-75"},[_c('img',{staticClass:"img-fluid",attrs:{"src":require("../assets/images/logo/logo-crm-dark.png"),"alt":"looginpage"}})]),_c('h4',{staticClass:"text-center"},[_vm._v("Entre na sua conta")]),_c('p',{staticClass:"text-center"},[_vm._v("Preencha os seus dados de acesso para entrar")]),_c('form',{staticClass:"theme-form",on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}}},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"username"}},[_vm._v("Usuário")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.username),expression:"username"}],staticClass:"form-control",class:{ 'is-invalid': _vm.submitted && !_vm.username },attrs:{"type":"text","name":"username"},domProps:{"value":(_vm.username)},on:{"input":function($event){if($event.target.composing)return;_vm.username=$event.target.value}}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.submitted && !_vm.username),expression:"submitted && !username"}],staticClass:"invalid-feedback"},[_vm._v(" Usuário obrigatório ")])]),_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"htmlFor":"password"}},[_vm._v("Senha")]),((_vm.type)==='checkbox')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.password),expression:"password"}],staticClass:"form-control",class:{
                        'is-invalid': _vm.submitted && !_vm.password,
                      },attrs:{"name":"password","type":"checkbox"},domProps:{"checked":Array.isArray(_vm.password)?_vm._i(_vm.password,null)>-1:(_vm.password)},on:{"change":function($event){var $$a=_vm.password,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.password=$$a.concat([$$v]))}else{$$i>-1&&(_vm.password=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.password=$$c}}}}):((_vm.type)==='radio')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.password),expression:"password"}],staticClass:"form-control",class:{
                        'is-invalid': _vm.submitted && !_vm.password,
                      },attrs:{"name":"password","type":"radio"},domProps:{"checked":_vm._q(_vm.password,null)},on:{"change":function($event){_vm.password=null}}}):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.password),expression:"password"}],staticClass:"form-control",class:{
                        'is-invalid': _vm.submitted && !_vm.password,
                      },attrs:{"name":"password","type":_vm.type},domProps:{"value":(_vm.password)},on:{"input":function($event){if($event.target.composing)return;_vm.password=$event.target.value}}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.submitted && !_vm.password),expression:"submitted && !password"}],staticClass:"invalid-feedback"},[_vm._v(" Senha obrigatória ")]),_c('div',{staticClass:"show-hide",on:{"click":_vm.showPassword}},[_c('i',{staticClass:"fa fa-eye-slash"})])]),_c('div',{staticClass:"form-group mt-3 mb-0"},[_c('button',{staticClass:"btn btn-primary btn-block",attrs:{"disabled":_vm.loggingIn}},[_vm._v(" Entrar ")])]),(this.error)?_c('div',{staticClass:"form-group mt-3 mb-0 text-center"},[_c('b-badge',{attrs:{"variant":"danger"}},[_vm._v(_vm._s(this.error))])],1):_vm._e()])])],1)],1)])])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }