<template>
    <div>
        <Breadcrumbs title="Usuários" />

        <!-- Container-fluid starts-->
        <div class="container-fluid">
            <div class="row">
                <div class="col-sm-12">
                    <px-card>
                        <div slot="with-padding">
                            <div class="form theme-form">
                                <div class="row">
                                    <div class="col-sm-3 pt-3">
                                        <div class="form-group mb-0">
                                            <label class="d-inline-flex align-items-center w-100">
                                                Filtrar:
                                                <b-form-input :model="filter" type="search"
                                                    placeholder="Digite para procurar..."
                                                    class="form-control form-control-sm ml-2" @keyup="debounceInput"
                                                    @click="debounceInput">
                                                </b-form-input>
                                            </label>
                                        </div>
                                    </div>
                                    <div class="col-sm-3 px-5">

                                    </div>
                                    <div class="col-sm-3 px-5">

                                    </div>
                                    <div class="col-sm-3 text-right  pt-3">
                                        <div class="form-group mb-0">
                                            <label class="d-inline-flex align-items-center">
                                                Exibir&nbsp;
                                                <b-form-select v-model="perPage" size="sm" :options="pageOptions"
                                                    @change="savePerPage">
                                                </b-form-select>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </px-card>
                </div>
            </div>
        </div>
        <!-- Container-fluid Ends-->

        <!-- Container-fluid starts-->
        <div class="container-fluid">
            <div class="row">
                <div class="col-sm-12">
                    <px-card>
                        <div slot="with-padding">
                            <div class="form theme-form">
                                <div class="row">
                                    <div class="col-sm-12">
                                        <div class="form-group mb-0">

                                            <div class="table-responsive mb-0">
                                                <b-table class="table-striped mb-0" style="font-size: 12px;"
                                                    :items="dataList" :fields="fields" responsive="sm"
                                                    :per-page="perPage" :current-page="currentPage"
                                                    :sort-by.sync="sortBy" :sort-desc.sync="sortDesc"
                                                    :sort-compare="sortCompare" :filter="filter"
                                                    :filter-included-fields="filterOn" @filtered="onFiltered"
                                                    @sort-changed="onSort">




                                                    <template v-slot:cell(user_name)="{ item }">

                                                        <router-link tag="a" v-if="item.user_name"
                                                            class="font-weight-bold action-edit primary"
                                                            :to="{ path: '/users/' + item.id }">
                                                            {{ item.user_name }}
                                                        </router-link>


                                                    </template>

                                                    <template v-slot:cell(user_email)="{ item }">
                                                        <span v-if="item.user_email">{{ item.user_email }}</span>
                                                    </template>

                                                    <template v-slot:cell(telefone)="{ item }">
                                                        <span v-if="item.user_meta['telefone']">{{
                                                                item.user_meta['telefone']
                                                        }}</span>
                                                    </template>

                                                    <template v-slot:cell(user_role)="{ item }">
                                                        <span v-if="item.user_role">{{ item.user_role === "administrator" ? "Administrador" : "Vendas" }}</span>
                                                    </template>





                                                    <template v-slot:cell(user_status)="{ item }">
                                                        <span
                                                            :class="item.user_status === 'active' ? 'text-success' : 'text-secondary'"><i
                                                                :class="item.user_status === 'active' ? 'fa fa-check' : 'fa fa-close'"></i>
                                                            {{ item.user_status === 'active' ? 'Ativado' : 'Desativado'
                                                            }}</span>
                                                    </template>


                                                    <template v-slot:cell(actions)="{ item }">

                                                        <b-link class="text-success font-weight-bold"
                                                            v-if="item.user_meta && item.user_meta['telefone']"
                                                            @click="callUser(item.user_meta['telefone'])">
                                                            <i class="fa fa-whatsapp"></i> Entrar em contato
                                                        </b-link>
                                                        <br v-if="item.user_meta && item.user_meta['telefone']" />

                                                        <router-link tag="a"
                                                            class="font-weight-bold action-edit primary"
                                                            :to="{ path: '/users/' + item.id }">
                                                            <i class="fa fa-edit"></i> Editar
                                                        </router-link>
                                                        <br  v-if="item.id != userID" />
                                                        <b-link  v-if="item.id != userID" class="action-delete font-weight-bold text-danger"
                                                            @click="deleteData(item)">
                                                            <i class="fa fa-trash"></i> Excluir
                                                        </b-link>
                                                    </template>


                                                    <template v-slot:cell(serialized)="{ item }">
                                                        <span>{{ JSON.stringify(item) }}</span>
                                                    </template>


                                                </b-table>
                                            </div>


                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-sm-12">
                                        <div class="form-group mb-0">



                                            <div class="dataLists_paginate paging_simple_numbers">
                                                <ul class="pagination pagination-rounded mb-0">
                                                    <!-- pagination -->
                                                    <b-pagination v-model="currentPage" :total-rows="rows"
                                                        :per-page="perPage"></b-pagination>
                                                </ul>
                                            </div>



                                        </div>
                                    </div>
                                </div>







                            </div>
                        </div>
                    </px-card>
                </div>
            </div>
        </div>
        <!-- Container-fluid Ends-->




    </div>
</template>
  
  
  
  
  
  
  
<script>
import Vue from 'vue'
import moment from 'moment'
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/antd.css'
import { listUsers, deleteUser } from '../../data/users'
import _ from 'lodash'
/**
 * Advanced table component
 */
export default {
    components: { VueSlider },
    data() {
        return {
            userID: localStorage.getItem("user_id"),
            loaded: false,
            dataList: [],
            fullDataList: [],
            title: 'Produtos',
            items: [
                {
                    text: 'Home',
                    href: '/',
                },
                {
                    text: 'Produtos',
                    active: true,
                },
            ],
            totalRows: 0,
            currentPage: 1,
            perPage: Vue.$cookies.get('perPageUsers') || 10,
            pageOptions: [10, 25, 50, 100],
            filter: '',
            filterOn: ['id', 'user_name', 'user_email', 'telefone', 'user_role', 'user_registered', 'user_status'],
            sortBy: Vue.$cookies.get('sortByUsers') || 'name',
            sortDesc: Vue.$cookies.get('sortDescUsers') === 'true' ? true : false,
            fields: [
                { key: 'id', label: 'ID', sortable: true, thClass: 'd-none', tdClass: 'd-none' },
                { key: 'user_name', label: 'Nome', sortable: true },
                { key: 'user_email', label: 'Email', sortable: true },
                { key: 'telefone', label: 'Telefone', sortable: true },
                { key: 'user_role', label: 'Função', sortable: true },
                { key: 'user_registered', label: 'Registrado em', sortable: true, formatter: this.formatDate, filterByFormatted: true },
                { key: 'user_status', label: 'Status', sortable: true, formatter: this.formatStatus, filterByFormatted: true },
                { key: 'actions', label: 'Ações' },
            ],

        }
    },
    computed: {
        /**
         * Total no. of records
         */
        rows() {
            return this.totalRows || this.dataList.length
        },
    },
    mounted() {
        // Set the initial number of items
        this.totalRows = this.dataList.length
        this.loader = this.$loading.show()
        this.loaded = false
        this.getDataList()
    },
    methods: {
        async getDataList() {
            this.fullDataList = await listUsers()



            this.dataList = [...this.fullDataList]




            this.loaded = true
            setTimeout(() => {
                this.loader.hide()
            }, 500)
        },
        async deleteData(item) {
            if (confirm('Tem certeza de que deseja excluir ' + item.user_title + '?') === true) {
                if ((await deleteUser(item)) > 0) {
                    let idx = this.dataList.indexOf(item)
                    this.dataList.splice(idx, 1)
                }
            }
        },
        normalizeString(str) {
            str.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")
            //str = str.replace(/[^a-z0-9/]/gi, ' ')
            return str
        },
        formatSerialize(value, column, data) {
            var str = JSON.stringify(data)
            return this.normalizeString(str)

        },
        formatStatus(value) {
            return value === 'published' ? 'Ativado' : 'Desativado'
        },
        formatDate(value) {
            try {
                return moment(value).format('DD/MM/YYYY @ H:mm')
            } catch (e) {
                return null
            }
        },
        sortCompare(a, b, key) {
            if (key === 'user_registered') {
                // Assuming the date field is a `Date` object, subtraction
                // works on the date serial number (epoch value)
                return a[key] - b[key]
            } else {
                // Let b-table handle sorting other fields (other than `date` field)
                return false
            }
        },
        /**
         * Search the table data with search input
         */
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        debounceInput: _.debounce(function (e) {
            var val = this.normalizeString(e.target.value)
            this.filter = val
        }, 250),
        savePerPage(e) {
            Vue.$cookies.set('perPageUsers', e)
        },
        onSort(e) {
            Vue.$cookies.set('sortByUsers', e.sortBy)
            Vue.$cookies.set('sortDescUsers', e.sortDesc)
        },
        callUser(phone) {
            window.open('https://wa.me/' + phone)
        }
    },
}
</script>